.header {
  color: white;
  font-size: 36px;
  font-weight: 700;
}

.text {
  color: white;
  font-size: 22px;
  text-indent: 10%;
}
