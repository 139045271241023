.header {
  color: white;
  font-size: 36px;
  font-weight: 700;
}

.text {
  color: white;
  font-size: 22px;
}

.anchor {
  text-decoration: none;
  color: rgb(151, 151, 151);
}

.anchor:hover {
  color: white;
}
